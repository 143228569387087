// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

console.log("[ProductDisplay] Init")
let parent = document.querySelectorAll(".productDisplay"),
sub = document.querySelectorAll(".ajaxAddToCart")[0],
plus = document.querySelectorAll(".plusQty")[0],
minus = document.querySelectorAll(".minusQty")[0],
basketQuantity = document.querySelectorAll(".productQty")[0],
basket = document.querySelectorAll(".js-sticky-basket")[0],
choices = document.querySelectorAll(".productChoices")[0],
productIdSource = document.querySelectorAll(".testTestTest")[0],
count,
attrPairs,
productId;
if(basketQuantity){
    if(parent.length > 0){
        window.addEventListener("DOMContentLoaded", () => {
            count = basketQuantity.value;
            increase();
            decrease();
            getAttr();
            productId = productIdSource.value;
            add();
        });
    }
}
    const increase = () => {
        plus.addEventListener("click", () =>{
            let max = parseInt(basketQuantity.max);

            if(max <= basketQuantity.value){
                basketQuantity.value = max;
                count = max;
            } else {
                basketQuantity.value ++;
                count = basketQuantity.value;
            }
        });
    }
    const decrease = () => {
        minus.addEventListener("click", () =>{
            if(basketQuantity.value > 1 ){
                basketQuantity.value --;
                count = basketQuantity.value;
            }
        });
    }

    const getAttr = () => {
        basketQuantity.addEventListener("input", () =>{
                let quantity = parseInt(basketQuantity.value);
                let max = parseInt(basketQuantity.max);
                if(quantity > 1 && max > quantity){
                    count = quantity;
                } else if(max <= quantity){
                    basketQuantity.value = max;
                    count = max;
                }else {
                    basketQuantity.value = 1;
                    count = basketQuantity.value;
                }
            });
        

        if(choices){
            choices.addEventListener("change", () => {
                let attrx = document.querySelectorAll(".dk-option-selected")[0],
                select = document.querySelectorAll(".productChoice")[0].children[1],
                value,
                optionC;
                for( const option of select.children){
                        if(option.value === attrx.dataset.value){
                            optionC = option.dataset.value;
                        }
                }
                if(select.dataset.attribute === "colour"){
                    value = 1
                } else {
                    value = 2
                }
                // COLOUR = 1 SIZE = 2 FOR ATTRIBUTE
                // VALUE = SIZE OR COLOUR IE - DARK BACKED FOR VALUE   
                let jsonAttr,
                str = `{"attribute": ${value}, "value": "${optionC}"}`
                jsonAttr = JSON.parse(str)
                attrPairs = jsonAttr
                return attrPairs
            });
        }
    }
    const add = () => {
     
     sub.addEventListener("click", (e) => {
        e.preventDefault();
             if ( $('select.productOpts').length > 0 ) {
                if ( $('select.productOpts').val() === '' ) {
                    let alert = document.querySelectorAll(".selectAlert")[0];
                    alert.innerHTML = "Please select a product option below."
                    choices.style.border = "1px solid red"
                    setTimeout(function(){ 
                        alert.innerHTML = ""
                        choices.style.border = "none"
                    }, 3000);
                } else {
                    runAjax()
                }
            } else {
                    runAjax()
        }
    });
}
    const runAjax = () => {
        let stringified = JSON.stringify(attrPairs)
        $.ajax({
            async: false,
            method: "POST",
            data:{
                product: productId,
                qty: count,
                attr: stringified,
            },	
            url: '/js/xhr/addToCart.php',
                success:function(data) {
                    basketToggle();
                    
                },
                error: function(err){
                    Swal.fire({
                        title: 'Sorry',
                        text: err.responseJSON.message,
                        icon: 'error',
                        customClass: {
                            cancelButton: 'button',
                            confirmButton: 'button'
                        },
                        buttonsStyling: false
                    })

            }
        }).done(basketQuantity.value = 1);
    }

    const basketToggle = () => {
        basket.classList.add('active');
        document.body.classList.add('nav-active');
    }


    document.addEventListener('DOMContentLoaded', function(){
        let plyrTriggers = [...document.querySelectorAll(".player")];
        console.log(plyrTriggers);
        if(plyrTriggers.length > 0){
            for(let i = 0; i < plyrTriggers.length; i++){

                plyrTriggers[i].id = "player" + i;
         
                const player = new Plyr("#player" + i, {
                    captions: {active: true}, 
                    autoplay:true,
                    muted: true,
                    loop: {active:true},
                    controls: ['play', 'mute']
                    });
                window.player = player;
            }
        }
    });
